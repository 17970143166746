import React, { useEffect } from 'react';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from 'react-bootstrap/Tooltip';
import ListGroup from 'react-bootstrap/ListGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFileImport,
  faFilePdf,
  faFileImage,
  faFolder
} from '@fortawesome/free-solid-svg-icons';
import { useAuthPermissionsForRoles } from '../../utils/auth-provider';
import Loading from '../loading';
import LoadingMini from '../loading-mini';
import { Category, File } from '../../../../server/src/types/entities';
//import useClientFiles from './use-client-files';
import createClientFilesContext from './context';
import './index.scss';

const {
  Provider,
  //useStore,
  useClient,
  useClientFiles,
  useFileCategories,
  useActiveFileId
} = createClientFilesContext();

type FileListItemProps = {
  file: File;
  categoryId: number;
  folderId: number;
};

function FileListItem({ file, categoryId, folderId }: FileListItemProps) {
  const navigate = useNavigate();
  const { activeFileId, setActiveFileId } = useActiveFileId();

  return (
    <ListGroup.Item
      variant={activeFileId === file.id ? 'info' : ''}
      className="my-1 ps-3 pe-1 fw-bold"
      style={{ fontSize: '0.6em' }}
      as="div"
      action
      onClick={() => {
        setActiveFileId(file.id);
        navigate(file.id.toString(), { replace: true });
      }}
    >
      <FontAwesomeIcon
        className="me-1 text-muted"
        icon={file.mimeType === 'application/pdf' ? faFilePdf : faFileImage}
        size="xl"
      />
      <span>{file.displayName || file.originalName}</span>
    </ListGroup.Item>
  );
}

type FileListProps = {
  files: File[];
  categoryId: number;
  folderId: number;
};

function FileList({ files, categoryId, folderId }: FileListProps) {
  return (
    <ListGroup className="h-auto w-auto mt-2 p-0 g-0">
      {files?.map((file) => {
        return (
          <FileListItem
            key={file.id.toString()}
            file={file}
            categoryId={categoryId}
            folderId={folderId}
          />
        );
      })}
    </ListGroup>
  );
}

type CategoryListItemProps = {
  clientId: number;
  category: Category;
};

function CategoryListItem({ clientId, category }: CategoryListItemProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles
    //fileRejections
  } = useDropzone({
    //onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp'],
      'image/png': ['.png'],
      'image/avif': ['.avif'],
      'image/webp': ['.webp']
    }
  });

  const {
    uploading,
    uploadPercent,
    uploadResult,
    /*uploadError,*/ uploadReset,
    downloading,
    downloadedFiles
  } = useClientFiles(clientId, category.id, acceptedFiles);

  useEffect(() => {
    uploadReset();
  }, [uploadResult, uploadReset]);

  return (
    <ListGroup.Item
      active={isDragActive}
      className="px-1 py-1 mb-1"
      as="div"
      disabled={uploading || downloading}
    >
      <div
        {...getRootProps({
          style: { width: '190px', height: '1.5em' },
          className: `my-1 position-relative overflow=hidden`
        })}
      >
        <div
          style={{ zIndex: '500' }}
          className="position-absolute start-0 top-0 end-0 bottom-0"
        >
          <span
            className="text-nowrap fw-bold"
            style={{ fontSize: '0.8em' }}
          >
            {category.name}
          </span>
        </div>
        <div
          style={{ zIndex: '501' }}
          className="position-absolute start-0 top-0 end-0 bottom-0"
        >
          <label className="w-100">
            <FontAwesomeIcon
              className="float-end text-muted"
              icon={faFileImport}
              size="sm"
            />
          </label>
        </div>
        {/* <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-dropzone">
              Drop Files or Click to Add Files
            </Tooltip>
          }
        > */}
        <div
          style={{ zIndex: '502' }}
          className="position-absolute start-0 top-0 end-0 bottom-0 opacity-0"
        >
          <input
            {...getInputProps()}
            className="m-0 p-0"
          />
        </div>
        {/* </OverlayTrigger> */}
      </div>
      <div
        style={{ width: '190px' }}
        className="my-1 position-relative overflow=hidden"
      >
        {downloading ? (
          <LoadingMini />
        ) : uploading ? (
          <div className="h-auto w-100 mt-2 p-0 g-0">
            <div
              className="h-auto w-100 my-1 fw-bold d-flex flex-row justify-content-center"
              style={{ fontSize: '0.6em' }}
            >
              <span>{`Uploading ${acceptedFiles.length.toString()} Files`}</span>
            </div>
            <ProgressBar now={uploadPercent} />
          </div>
        ) : isDragActive ? (
          <div className="h-auto w-100 mt-2 p-0 g-0 d-flex flex-row justify-content-center">
            <span>{`Drop Files Here`}</span>
          </div>
        ) : downloadedFiles ? (
          <FileList
            files={downloadedFiles}
            categoryId={category.id}
            folderId={0}
          />
        ) : null}
      </div>
    </ListGroup.Item>
  );
}

type CategoryListProps = {
  clientId: number;
};

function CategoryList({ clientId }: CategoryListProps) {
  const { isLoading, data } = useFileCategories();

  return isLoading ? (
    <Loading />
  ) : (
    <ListGroup className="h-auto w-auto mt-2 p-0 g-0">
      {data?.map((category) => {
        return (
          <CategoryListItem
            key={category.id.toString()}
            category={category}
            clientId={clientId}
          />
        );
      })}
    </ListGroup>
  );
}

type FolderListProps = {
  clientId: number;
};

function FolderList({ clientId }: FolderListProps) {
  // const { isLoading, data } = useFileCategories();

  // return isLoading ? (
  //   <Loading />
  // ) : (
  //   <ListGroup className="h-auto w-auto mt-2 p-0 g-0">
  //     {data?.map((category) => {
  //       return (
  //         <FolderListItem
  //           key={category.id.toString()}
  //           category={category}
  //           clientId={clientId}
  //         />
  //       );
  //     })}
  //   </ListGroup>
  // );
  return (
    <div  className="h-auto w-auto mt-2 p-0 g-0" ></div>
  )
}

function ClientFiles() {
  const parms = useParams();
  const navigate = useNavigate();
  const permissions = useAuthPermissionsForRoles();

  const canUseFileFolders =
    permissions && permissions.clientFileFolders?.canRead;

  let clientId: number;
  try {
    if (!parms.clientId) throw new Error();
    clientId = Number.parseInt(parms.clientId, 10);
  } catch (e) {
    clientId = 0;
    navigate(-1);
  }

  const { data } = useClient(clientId);

  const clientName =
    data?.profile?.firstName && data?.profile?.lastName
      ? `${data.profile.lastName}, ${data.profile.firstName}`
      : data?.email || '';
  // const branchName = `Branch: ${
  //   client.data?.loanOfficer?.branch?.name ? client.data.loanOfficer.branch.name : ''
  // }`;
  // const mloName =
  //   client.data?.loanOfficer?.profile?.firstName &&
  //   client.data?.loanOfficer?.profile?.lastName
  //     ? `MLO: ${client.data.loanOfficer.profile.firstName} ${client.data.loanOfficer.profile.lastName}`
  //     : `MLO: ${client.data?.loanOfficer?.email || ''}`;

  return (
    <Provider>
      <Container
        fluid
        className="position-relative flex-nowrap d-flex flex-column h-100 w-100 p-3"
      >
        <Row className="position-relative overflow-hidden">
          <Col>
            <Container
              fluid
              className="bg-light border rounded"
            >
              <h3>{clientName}</h3>
            </Container>
          </Col>
        </Row>
        <Row className="position-relative h-100 w-100 flex-grow-1 d-flex flex-row justify-content-center gx-0 pt-1 overflow-hidden">
          <Col className="col-auto h-100 w-auto flex-grow-0 d-flex flex-column bg-secondary bg-gradient border rounded overflow-hidden">
            <Row className={`${canUseFileFolders ? 'h-50' : 'h-100'} w-auto flex-grow-1 d-flex flex-column g-0 p-0 m-0`}>
              <Col className="col-auto h-100 w-auto flex-grow-0 d-flex flex-column">
                <Container className="h-auto w-auto flex-grow-0 g-0 p-0 my-1 ms-1 me-0">
                  <FontAwesomeIcon
                    className="text-light"
                    icon={faFile}
                    size="xl"
                  />
                  <span className="ms-2 fw-bold">Files</span>
                </Container>
                <Container className="h-100 w-auto flex-grow-1 d-flex flex-column g-0 p-0 m-0 bg-light border rounded overflow-auto">
                  <CategoryList clientId={clientId} />
                </Container>
              </Col>
            </Row>
            {canUseFileFolders && (
              <Row className="h-50 w-auto flex-grow-1 d-flex flex-column g-0 p-0 m-0">
                <Col className="col-auto h-100 w-auto flex-grow-0 d-flex flex-column">
                  <Container className="h-auto w-auto flex-grow-0 g-0 p-0 my-1 ms-1 me-0">
                    <FontAwesomeIcon
                      className=""
                      style={{ color: 'goldenrod' }}
                      icon={faFolder}
                      size="xl"
                    />
                    <span className="ms-2 fw-bold">Folders</span>
                  </Container>
                  <Container className="h-100 w-auto flex-grow-1 d-flex flex-column g-0 p-0 m-0 bg-light border rounded overflow-auto">
                    <FolderList clientId={clientId} />
                  </Container>
                </Col>
              </Row>
            )}
          </Col>
          <Col className="h-100 w-100 flex-grow-1 d-flex flex-column ps-1">
            <Container
              fluid
              className="h-100 w-100 flex-grow-1 d-flex flex-column px-1 bg-secondary bg-gradient border rounded overflow-hidden"
            >
              <Outlet />
            </Container>
          </Col>
        </Row>
      </Container>
    </Provider>
  );
}
export default ClientFiles;

// [
//   {
//     "file": {
//       "path": "asset-manifest.json"
//     },
//     "errors": [
//       {
//         "code": "file-invalid-type",
//         "message": "File type must be image/*,.jpg,.jpeg,.png,application/pdf,.pdf"
//       }
//     ]
//   },
//   {
//     "file": {
//       "path": "index.html"
//     },
//     "errors": [
//       {
//         "code": "file-invalid-type",
//         "message": "File type must be image/*,.jpg,.jpeg,.png,application/pdf,.pdf"
//       }
//     ]
//   }
// ];
