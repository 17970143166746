import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  useAuthIsLoggedIn,
  usePasswordExpired
} from '../../utils/auth-provider';
//import './index.scss';

export default function HomePage() {
  const isLoggedIn = useAuthIsLoggedIn();
  const passwordExpired = usePasswordExpired();

  if (isLoggedIn) {
    if (passwordExpired) {
      return <Navigate to="/passwordchange" />;
    } else {
      return (
        <Navigate
          to="/clients"
          replace
        />
      );
    }
  } else {
    return (
      <Navigate
        to="/login"
        replace
      />
    );
  }
}
