import React from 'react';
import { trpc } from '../../utils/trpc';
import { useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  //faFile,
  //faFileImport,
  faFilePdf,
  faFileImage,
  //faFolder
} from '@fortawesome/free-solid-svg-icons';
import Loading from '../loading';
import './index.scss';

function FileView() {
  const parms = useParams();
  const navigate = useNavigate();

  let fileId: number;
  try {
    if (!parms.fileId) throw new Error();
    fileId = Number.parseInt(parms.fileId, 10);
  } catch (e) {
    navigate(-1);
    return null;
  }

  const { isLoading, data } = trpc.useQuery(['clientfiles.getById', fileId]);

  if (isLoading || !data?.mimeType) {
    return <Loading />;
  } else {
    return (
      <Row
        className={`h-100 w-auto flex-grow-1 d-flex flex-column g-0 p-0 m-0`}
      >
        <Col className="col-auto h-100 w-auto flex-grow-0 d-flex flex-column">
          <Container className="h-auto w-auto flex-grow-0 g-0 p-0 my-1 ms-1 me-0">
            <FontAwesomeIcon
              className="text-light"
              icon={data.mimeType === 'application/pdf' ? faFilePdf : faFileImage}
              size="xl"
            />
            <span className="ms-2 fw-bold">{data.originalName}</span>
          </Container>
          {data.mimeType === 'application/pdf' ? (
            <Container
              fluid
              className="h-100 m-0 px-0 py-0"
            >
              <iframe
                title={data.originalName}
                src={`/files/${fileId}`}
                className="w-100 h-100"
              />
            </Container>
          ) : (
            <Container
              fluid
              className="h-100 m-0 px-0 py-0"
            >
              <Image
                src={`/files/${fileId}`}
                alt={data.originalName}
                className="w-100 h-100"
                style={{
                  objectPosition: 'center',
                  objectFit: 'contain'
                }}
              />
            </Container>
          )}
        </Col>
      </Row>
    );
  }
}

//object-position: center;
//object-fit: contain;

// <Container className="bg-light w-100 h-100 border rounded">
//   <div>
//     <pre>
//       <code>{JSON.stringify(parms, null, 2)}</code>
//     </pre>
//   </div>
// </Container>

export default FileView;
