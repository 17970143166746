import React from 'react';
import { Outlet } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useQueryClient } from '../../utils/react-query-client';
import { trpc, trpcClient } from '../../utils/trpc';
import { AuthProvider } from '../../utils/auth-provider';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AppNavBar from '../../components/navbar';
import './index.scss';

export default function AppLayout() {
  const queryClient = useQueryClient();

  return (
    <trpc.Provider
      client={trpcClient}
      queryClient={queryClient}
    >
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Container
            fluid
            className="d-flex flex-column w-100 h-100 m-0 p-0 bg-dark"
          >
            <Row className="w-100 h-auto p-0 m-0 flex-grow-0">
              <Col className="p-0 m-0">
                <AppNavBar />
              </Col>
            </Row>
            <Row className="layout-outlet-row w-100 h-100 p-0 m-0 flex-grow-1 overflow-hidden">
              <Outlet />
            </Row>
          </Container>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </trpc.Provider>
  );
}
