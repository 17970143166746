import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useSetPassword, usePasswordExpired } from '../../utils/auth-provider';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import './index.scss';

const FormDataSchema = z.object({
  password1: z.string().min(1, { message: 'Required' }),
  password2: z.string().min(1, { message: 'Required' })
});
type FormData = z.infer<typeof FormDataSchema>;

export default function PasswordChangePage() {
  const [alertError, setAlertError] = useState(null);
  const passwordExpired = usePasswordExpired();
  const setPassword = useSetPassword();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    if (formData.password1 !== formData.password2) {
      setError('password2', {
        type: 'custom',
        message: 'Passwords Must Match!'
      });
    } else {
      setPassword(formData.password1)
        .then(() => {
          navigate(-1);
        })
        .catch((err) => {
          setAlertError(err);
        });
    }
  };
  type SubmitHandlerType = ReturnType<typeof onSubmit>;

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<FormData>({
    resolver: zodResolver(FormDataSchema)
  });

  return (
    <Container
      fluid
      className="flex-nowrap h-100 p-0 m-0 g-0"
    >
      <Row className="flex-nowrap justify-content-center w-100 h-100 m-0 overflow-hidden">
        <Col
          lg="4"
          className="mt-3"
        >
          {alertError ? (
            <Alert
              variant="danger"
              onClose={() => setAlertError(null)}
              dismissible
            >
              <Alert.Heading>Error occurred setting password</Alert.Heading>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => setAlertError(null)}
                  variant="outline-light"
                >
                  Close
                </Button>
              </div>
            </Alert>
          ) : (
            <Container className="flex-grow-0 bg-light border p-2 rounded mb-2 overflow-hidden">
              <Form
                noValidate
                onSubmit={handleSubmit(onSubmit as SubmitHandlerType)}
              >
                <Stack>
                  <Form.Label>
                    {passwordExpired
                      ? 'Password Expired, Please Enter a New One'
                      : 'Change Password'}
                  </Form.Label>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Text>New Password</Form.Text>
                    <Controller
                      control={control}
                      name="password1"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          type="password"
                          placeholder="Enter new password"
                          disabled={isSubmitting}
                          isInvalid={!!errors.password1}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password1?.message
                        ? errors.password1.message
                        : 'Invalid Password'}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Text>Verify New Password</Form.Text>
                    <Controller
                      control={control}
                      name="password2"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          type="password"
                          placeholder="Enter new password again"
                          disabled={isSubmitting}
                          isInvalid={!!errors.password2}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password2?.message
                        ? errors.password2.message
                        : 'Invalid Password'}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                  <Button
                    variant=""
                    type="button"
                    disabled={passwordExpired || isSubmitting}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Form>
            </Container>
          )}
        </Col>
      </Row>
    </Container>
  );
}
