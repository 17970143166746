import React, {
  useRef,
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useSyncExternalStore
} from 'react';
import axios from 'axios';
import superjson from 'superjson';
import { trpc } from '../../utils/trpc';
import {
  ClientFileUploadInfo,
  ClientFileUploadResultData,
  ClientFileUploadResult
} from '../../../../server/src/types/routers';

type Store = {
  activeFileId: number
};

export default function createClientFilesContext() {
  function useStoreData(): {
    get: () => Store;
    set: (value: Partial<Store>) => void;
    subscribe: (callback: () => void) => () => void;
  } {
    const store = useRef({ activeFileId: 0 });

    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set<() => void>());

    const set = useCallback((value: Partial<Store>) => {
      store.current = { ...store.current, ...value };
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      subscribe
    };
  }

  type UseStoreDataReturnType = ReturnType<typeof useStoreData>;

  const StoreContext = createContext<UseStoreDataReturnType | null>(null);

  function Provider({ children }: { children: React.ReactNode }) {
    return (
      <StoreContext.Provider value={useStoreData()}>
        {children}
      </StoreContext.Provider>
    );
  }

  function useStore<SelectorOutput>(
    selector: (store: Store) => SelectorOutput
  ): [SelectorOutput, (value: Partial<Store>) => void] {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error('Store not found');
    }

    const state = useSyncExternalStore(store.subscribe, () =>
      selector(store.get())
    );

    return [state, store.set];
  }

  function useClient(clientId: number) {
    return trpc.useQuery(['clients.getWithRelationsById', clientId]);
  }

  function useClientFiles(
    clientId: number,
    categoryId: number,
    filesToUpload: File[]
  ) {
    const trpcUtils = trpc.useContext();

    const queryKey = useMemo(() => {
      return { clientId, categoryId };
    }, [clientId, categoryId]);

    const { isLoading, data } = trpc.useQuery([
      'clientfiles.getByClientIdCategory',
      queryKey
    ]);

    const emptyUploadResultRef = useRef<ClientFileUploadResultData>({
      newFiles: [],
      failed: []
    });
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadPercent, setUploadPercent] = useState<number>(0);
    const [uploadResult, setUploadResult] = useState<ClientFileUploadResultData>(
      emptyUploadResultRef.current
    );
    const [uploadError, setUploadError] = useState<boolean>(false);

    const axiosRef = useRef(
      axios.create({
        baseURL: '/',
        //timeout: 1000,
        //headers: { 'X-Custom-Header': 'foobar' },
        onUploadProgress: (progEvent) => {
          if (progEvent.progress) {
            setUploadPercent(Math.floor(progEvent.progress * 100));
          }
        }
      })
    );

    const uploadReset = useCallback(() => {
      setUploadPercent(0);
      setUploadResult(emptyUploadResultRef.current);
      setUploadError(false);
    }, []);

    useEffect(() => {
      let ignore = false;

      if (filesToUpload.length > 0) {
        const formData = new FormData();
        const clientFileUploadInfo: ClientFileUploadInfo = {
          clientId,
          categoryId,
          files: []
        };
        filesToUpload.forEach((file) => {
          clientFileUploadInfo.files.push({
            name: file.name,
            size: file.size,
            mimeType: file.type,
            lastModified: new Date(file.lastModified)
          });
          formData.append('files', file);
        });
        formData.append(
          'clientFileUploadInfo',
          superjson.stringify(clientFileUploadInfo)
        );

        setUploadPercent(0);
        setUploadError(false);
        setUploading(true);

        // // this works too
        // fetch('http://localhost:3001/files', {
        //   method: 'POST',
        //   body: formData,
        //   // headers: {
        //   //   'Content-Type': 'multipart/form-data'
        //   // }
        // })
        // .then((res) => console.log(res))
        // .catch((err) => {/** */});

        axiosRef.current
          .postForm<ClientFileUploadResult>('files', formData, {})
          .then((res) => {
            if (!ignore) {
              //console.log(JSON.stringify(res.data, null, 2));
              setUploadResult(res.data.data);
              if (res.data.data.newFiles.length) {
                trpcUtils.invalidateQueries([
                  'clientfiles.getByClientIdCategory',
                  queryKey
                ]);
              }
            }
            setUploading(false);
          })
          .catch((err) => {
            setUploadError(true);
            setUploading(false);
          });
      }

      return () => {
        ignore = true;
      };
    }, [filesToUpload, clientId, categoryId, queryKey, trpcUtils]);

    return {
      uploading,
      uploadPercent,
      uploadResult,
      uploadError,
      uploadReset,
      downloading: isLoading,
      downloadedFiles: data
    };
  }

  function useFileCategories() {
    return trpc.useQuery(['categories.get']);
  }

  function useActiveFileId() {
    const [activeFileId, setActiveFileId] = useStore((store) => store.activeFileId);

    return {
      activeFileId,
      setActiveFileId: (id: number) => {
        setActiveFileId({activeFileId: id});
      }
    };
  }

  return {
    Provider,
    useStore,
    useClient,
    useClientFiles,
    useFileCategories,
    useActiveFileId
  };
}
