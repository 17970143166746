import React, { useState, ChangeEvent } from 'react';
import { trpc } from '../../utils/trpc';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import Loading from '../loading';
import { Client } from '../../../../server/src/types/entities';
import './index.scss';

type ClientListItemProps = {
  client: Client;
};

function ClientListItem({ client }: ClientListItemProps) {
  const navigate = useNavigate();
  const clientName =
    client.profile?.firstName && client.profile?.lastName
      ? `${client.profile.lastName}, ${client.profile.firstName}`
      : client.email;
  const branchName = `Branch: ${
    client.loanOfficer?.branch?.name ? client.loanOfficer.branch.name : ''
  }`;
  const mloName =
    client.loanOfficer?.profile?.firstName &&
    client.loanOfficer?.profile?.lastName
      ? `MLO: ${client.loanOfficer.profile.firstName} ${client.loanOfficer.profile.lastName}`
      : `MLO: ${client.loanOfficer?.email || ''}`;

  return (
    <ListGroup.Item
      className="my-1"
      as="div"
      action
      onClick={() => {
        navigate(client.id.toString());
      }}
    >
      <h5 className="card-title">{clientName}</h5>
      <div className="ms-3 my-2">
        <h6 className="card-subtitle text-muted">{branchName}</h6>
      </div>
      <div className="ms-3">
        <h6 className="card-subtitle text-muted">{mloName}</h6>
      </div>
    </ListGroup.Item>
  );
}

type ListProps = {
  searchText: string;
  showAll: boolean;
};

function List({ searchText, showAll }: ListProps) {
  const { isLoading, data } = trpc.useQuery([
    'clients.getLike',
    { searchText: searchText, getAll: showAll }
  ]);

  return isLoading ? (
    <Loading />
  ) : (
    <Container
      fluid
      className="flex-grow-1 d-flex flex-column p-0 mb-2 g-0 overflow-auto"
    >
      <ListGroup className="flex-grow-0 flex-shrink-0 mt-2 p-0 g-0">
        {data?.map((client) => {
          return (
            <ClientListItem
              key={client.id.toString()}
              client={client}
            />
          );
        })}
      </ListGroup>
    </Container>
  );
}

function ClientList() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>('');
  const [showAll, setShowAll] = useState<boolean>(false);

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const onShowAllChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newShowAll = event.target.checked;
    setShowAll(newShowAll);
    if (newShowAll) {
      setSearchText('');
    }
  };

  return (
    <Container
      fluid
      className="flex-nowrap h-100 m-0 p-0"
    >
      <Row className="flex-nowrap justify-content-center w-100 h-100 m-0 ">
        <Col
          lg="5"
          className="h-100 d-flex flex-column"
        >
          <Container className="flex-grow-0 my-3">
            <Form className="">
              <Button
                className="d-block mx-auto"
                onClick={() => {
                  navigate('new');
                }}
              >
                Add New Client...
              </Button>
              <Form.Control
                type="search"
                placeholder="Search For Client By Name"
                className="my-2"
                aria-label="Search"
                value={searchText}
                onChange={onSearchTextChange}
                disabled={showAll}
              />
              <Form.Group
                className="d-flex flex-row justify-content-center"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  label="Show All Clients"
                  checked={showAll}
                  onChange={(event) => {
                    onShowAllChange(event);
                  }}
                />
              </Form.Group>
            </Form>
          </Container>
          <Container className="container-lg flex-grow-1 d-flex flex-column bg-light border rounded mb-2 overflow-hidden">
            <List
              searchText={searchText}
              showAll={showAll}
            />
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ClientList;
