import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthIsLoggedIn } from '../../utils/auth-provider';

type Props = {
  children: ReactElement;
};

export default function ProtectedRoute({ children }: Props) {
  const isLoggedIn = useAuthIsLoggedIn();

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/"
        replace
      />
    );
  }

  return children;
}
