import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';

function AdminPage() {
  return (
    <Container
      fluid
      className="flex-nowrap h-100 m-0 p-0"
    >
      <Row className="flex-nowrap justify-content-center w-100 h-100 m-0 flex-row">
        <Col
          lg="8"
          className="m-3 bg-light border rounded flex-grow-0 overflow-auto"
        >
          <h5>Admin page, under construction...</h5>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminPage;
