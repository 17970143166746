import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

export default function LoadingMini() {
  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-center h-100 p-0 m-0 g-0"
    >
      <Row className="d-flex flex-row justify-content-center">
        <Col xs="auto">
          <div>
            <Container>
              <Row className="justify-content-center">
                <FontAwesomeIcon
                  icon={faFile}
                  size="xs"
                  spinPulse
                />
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
