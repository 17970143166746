import superjson from 'superjson';
import { createReactQueryHooks } from '@trpc/react';
import type { ApiRouter } from '../../../server/src/types/routers';
import type { Operation, TRPCLink } from '@trpc/client';
import type { AnyRouter } from '@trpc/server';
//import { httpBatchLink } from '@trpc/client/links/httpBatchLink';
import { httpLink } from '@trpc/client/links/httpLink';
import PQueue from 'p-queue';
import { history, authStore } from './auth-provider';

const queue = new PQueue({ concurrency: 1 });

export type TokenRefreshProperties = {
  tokenRefreshNeeded: (op: Operation<unknown>) => boolean;
  fetchAccessToken: () => Promise<void>;
};

export const tokenRefreshLink =
  <AppRouter extends AnyRouter>({
    tokenRefreshNeeded,
    fetchAccessToken
  }: TokenRefreshProperties): TRPCLink<AppRouter> =>
  () => {
    return ({ prev, next, op }) => {
      void queue.add(async () => {
        const shouldRenew = tokenRefreshNeeded(op);

        if (shouldRenew) {
          // ok we need to refresh the token
          await fetchAccessToken();
        }

        next(op, (result) => {
          prev(result);
        });
      });
    };
  };

export const trpc = createReactQueryHooks<ApiRouter>(); //createTRPCClient<AppRouter>
// => { useQuery: ..., useMutation: ...}

export const trpcClient = trpc.createClient({
  url: 'api/v1/rpc',
  transformer: superjson,
  links: [
    tokenRefreshLink({
      tokenRefreshNeeded: (op) => {
        // on every request, this function is called
        if (op && op.context && !op.context?.noTokenRefresh) {
          const loggedInUser = authStore.getLoggedInUser();

          if (
            loggedInUser &&
            Date.now() > loggedInUser.tokenRefreshBy.getTime()
          ) {
            //console.log('** Token Refresh! **');
            return true;
          }
        }

        //console.log('** No Token Refresh! **');
        return false;
      },
      fetchAccessToken: async () => {
        // if true is returned from tokenRefreshNeeded, this function will be called

        // // do your magic to fetch a refresh token here
        // // example:
        // try {
        //   const res = (await fetch("/api/renew-token", {
        //     method: "POST",
        //   }).then((res) => res.json())) as { accessToken: string }

        //   saveAccessToken(res.accessToken) // save token to cookies
        // } catch (err) {

        //   // token refreshing failed, let's log the user out
        //   if (err instanceof Error && err.message.includes("401")) {
        //     clearAccessToken()
        //     location.reload()
        //   }
        // }
        const loggedInUser = authStore.refresh();
        if (!loggedInUser && history?.navigate) {
          history.navigate('/');
        }
      }
    }),
    httpLink({
      url: 'api/v1/rpc'
    })
  ]
  // // optional
  // headers() {
  //   return {
  //     authorization: getAuthCookie(),
  //   };
  // },
});

// export function useTrpcClient() {
//   const [trpcClient] = useState(() =>
//   );

//   return trpcClient;
// }

//import superjson from 'superjson';
//import { createTRPCClient } from '@trpc/client';
//import type { ApiRouter } from '../../server/src/types/routers';
//// Create a typesafe vanilla client
// const client = createTRPCClient<ApiRouter>({
//   url: '/api/v1',
//   transformer: superjson
// });
////const client = createTRPCClient<ApiRouter>({
////  url: 'YOUR_SERVER_URL',
////  transformer: superjson,
////  fetch(url, options) {
////    return fetch(url, {
////      ...options,
////      credentials: 'include',
////    });
////  },
////});
// client.mutation('auth.login', { username: '', password: '' });
