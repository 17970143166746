import { useState } from 'react';
import { QueryClient } from 'react-query';

export function useQueryClient() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            notifyOnChangeProps: 'tracked'
          }
        }
      })
  );

  return queryClient;
}
