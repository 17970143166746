import React, { useState } from 'react';
import { trpcClient } from '../../utils/trpc';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

const FormDataSchema = z.object({
  email: z.string().email({ message: 'Invalid Email Address' }).or(z.literal('')),
  firstName: z.string().min(1, { message: 'First Name Required' }),
  lastName: z.string().min(1, { message: 'Last Name Required' })
});
type FormData = z.infer<typeof FormDataSchema>;

export default function NewClient() {
  const [alertError, setAlertError] = useState(null);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    trpcClient
      .mutation('clients.create', {
        email: formData.email,
        profile: {
          firstName: formData.firstName,
          lastName: formData.lastName
        }
      })
      .then((newClient) => {
        navigate(`/clients/${newClient.id.toString()}`, { replace: true });
      })
      .catch((err) => {
        setAlertError(err);
      });
  };
  type SubmitHandlerType = ReturnType<typeof onSubmit>;

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm<FormData>({
    resolver: zodResolver(FormDataSchema)
  });

  return (
    <Container
      fluid
      className="flex-nowrap h-100 p-0 m-0 g-0"
    >
      <Row className="flex-nowrap justify-content-center w-100 h-100 m-0 overflow-auto">
        <Col
          lg="4"
          className="mt-3"
        >
          {alertError ? (
            <Alert
              variant="danger"
              onClose={() => setAlertError(null)}
              dismissible
            >
              <Alert.Heading>Error saving new client</Alert.Heading>
              {/* <pre>
                <code>{JSON.stringify(error, null, 2)}</code>
              </pre> */}
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => setAlertError(null)}
                  variant="outline-light"
                >
                  Close
                </Button>
              </div>
            </Alert>
          ) : (
            <Container className="flex-grow-0 bg-light border p-2 rounded mb-2 overflow-hidden">
              <Form
                noValidate
                onSubmit={handleSubmit(onSubmit as SubmitHandlerType)}
              >
                <Stack>
                  <Form.Label>New Client</Form.Label>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Text>Email</Form.Text>
                    <Controller
                      control={control}
                      name="email"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          type="email"
                          placeholder="Email (Optional)"
                          disabled={isSubmitting}
                          isInvalid={!!errors.email}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message
                        ? errors.email.message
                        : 'Invalid Email'}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicFirstName"
                  >
                    <Form.Text>First name</Form.Text>
                    <Controller
                      control={control}
                      name="firstName"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          type="text"
                          placeholder="First name"
                          disabled={isSubmitting}
                          isInvalid={!!errors.firstName}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName?.message
                        ? errors.firstName.message
                        : 'First Name Required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicLastName"
                  >
                    <Form.Text>Last name</Form.Text>
                    <Controller
                      control={control}
                      name="lastName"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          type="text"
                          placeholder="Last name"
                          disabled={isSubmitting}
                          isInvalid={!!errors.lastName}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName?.message
                        ? errors.lastName.message
                        : 'Last Name Required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                  <Button
                    variant=""
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Form>
            </Container>
          )}
        </Col>
      </Row>
    </Container>
  );
}

//<Button
//disabled={isLoggedIn}
//>
//Login
//</Button>
//<Button
//disabled={!isLoggedIn}
//onClick={() => {
//  logout();
//}}
//>
//Logout
//</Button>
