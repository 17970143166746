import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  useAuthLoading
  // useAuthIsLoggedIn,
  // useLogin,
  // useLogout,
  // useAuthUser,
  // useAuthPermissionsForRoles
} from '../utils/auth-provider';
import ProtectedRoute from '../components/protected-route';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import {
//   faTrashAlt,
//   faHandPaper,
//   faHandPointer
// } from '@fortawesome/free-solid-svg-icons';
import AppLayout from '../pages/app-layout';
import LoadingPage from '../pages/loading';
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import PasswordChangePage from '../pages/password-change';
import AdminPage from '../pages/admin';
import FilesPage from '../pages/files';
import NewClient from '../components/new-client';
import ClientList from '../components/client-list';
import ClientFiles from '../components/client-files';
import FileView from '../components/file-view';

// // Create a library of the FontAwesome icons we use
// library.add(faTrashAlt, faHandPaper, faHandPointer);

// // Alternative, explicitly load individual Font Awesome icons:
// import ReactDOM from 'react-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
// const element = <FontAwesomeIcon icon={faEnvelope} />
// ReactDOM.render(element, document.body)

export default function App() {
  const isLoading = useAuthLoading();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<AppLayout />}
        >
          {isLoading ? (
            <Route
              path="*"
              element={<LoadingPage />}
            />
          ) : (
            <>
              <Route
                index
                element={<HomePage />}
              />
              <Route
                path="login"
                element={<LoginPage />}
              />
              <Route
                path="passwordchange"
                element={
                  <ProtectedRoute>
                    <PasswordChangePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="admin"
                element={
                  <ProtectedRoute>
                    <AdminPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="clients"
                element={
                  <ProtectedRoute>
                    <FilesPage />
                  </ProtectedRoute>
                }
              >
                <Route
                  index
                  element={<ClientList />}
                />
                <Route
                  path="new"
                  element={<NewClient />}
                />
                <Route
                  path=":clientId"
                  element={<ClientFiles />}
                >
                  <Route
                    path=":fileId"
                    element={<FileView />}
                  />
                </Route>
              </Route>
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
