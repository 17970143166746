import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAuthLoading,
  useLogout,
  useAuthUser,
  useAuthPermissionsForRoles
} from '../../utils/auth-provider';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';
import './index.scss';

function AppNavBar() {
  const logout = useLogout();
  const navigate = useNavigate();
  const isLoading = useAuthLoading();
  const user = useAuthUser();
  const permissions = useAuthPermissionsForRoles();

  const isAdmin =
    permissions &&
    (permissions?.permissionData?.canWrite ||
      permissions?.roleData?.canWrite ||
      permissions?.securableData?.canWrite);

  return (
    <Navbar
      className="navbar navbar-expand-lg navbar-dark bg-primary p-3"
      expand="lg"
    >
      {/* <Container fluid> */}
      <Navbar.Brand href="/">NextFileUp</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        {user && (
          <Nav className="me-auto">
            <LinkContainer to="/clients">
              <Nav.Link href="/clients">Files</Nav.Link>
            </LinkContainer>
            {isAdmin && (
              <LinkContainer to="/admin">
                <Nav.Link href="/admin">Admin</Nav.Link>
              </LinkContainer>
            )}
          </Nav>
        )}
        <Navbar.Text>
          {isLoading ? 'Loading...' : user ? `Signed in as` : ''}
        </Navbar.Text>
        {user ? (
          <NavDropdown
            align="end"
            className="btn-link"
            title={`${
              user.profile?.firstName && user.profile.lastName
                ? `${user.profile.firstName} ${user.profile.lastName}`
                : user.email
            }`}
            id="nav-dropdown"
          >
            {/* <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3">
                Something else here
              </NavDropdown.Item>
              <NavDropdown.Divider /> */}
            <NavDropdown.Item
              onClick={() => {
                navigate('/passwordchange', { replace: false });
              }}
              eventKey="4.3"
            >
              Change Password
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={() => {
                logout()
                  .then(() => {
                    navigate('/', { replace: true });
                  })
                  .catch(() => {
                    /**NOOP*/
                  });
              }}
              eventKey="4.4"
            >
              Sign out
            </NavDropdown.Item>
          </NavDropdown>
        ) : null}
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}

export default AppNavBar;
