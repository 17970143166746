import React from 'react';
import {
  ReactNode,
  createContext,
  useContext,
  useSyncExternalStore,
  useCallback
} from 'react';
import { useNavigate } from 'react-router-dom';
import { trpcClient } from './trpc';
import { isEqualSimple } from '@react-hookz/deep-equal/esnext';
import { LoggedInUser } from '../../../server/src/types/routers';

const createStore = () => {
  let loading = true;
  const loadingListeners = new Set<() => void>();

  const getLoading = () => loading;

  const setLoading = (value: boolean) => {
    loading = value;
    loadingListeners.forEach((l) => l());
  };

  const subscribeLoading = (listener: () => void) => {
    loadingListeners.add(listener);
    return () => loadingListeners.delete(listener);
  };

  //TODO If we use JWT auth tokens we'll need to mirror loggedInUser in LocalStorage
  //   Since we're currently using cookies to store the tokens, the browser stores
  //   them for us.
  let loggedInUser: LoggedInUser | null = null;
  const loggedInUserListeners = new Set<() => void>();

  const getLoggedInUser = () => loggedInUser;

  const setLoggedInUser = (value: LoggedInUser | null) => {
    if (value && loggedInUser) {
      if (!isEqualSimple(value.user, loggedInUser.user)) {
        loggedInUser.user = value.user;
      }
      if (
        !isEqualSimple(
          value.permissionsForRoles,
          loggedInUser.permissionsForRoles
        )
      ) {
        loggedInUser.permissionsForRoles = value.permissionsForRoles;
      }
      loggedInUser.passwordExpired = value.passwordExpired;
      loggedInUser.token = value.token;
      loggedInUser.tokenExpires = value.tokenExpires;
      loggedInUser.tokenRefreshBy = value.tokenRefreshBy;
      loggedInUser.refreshToken = value.refreshToken;
      loggedInUser.refreshTokenExpires = value.refreshTokenExpires;
    } else {
      loggedInUser = value;
    }
    loggedInUserListeners.forEach((l) => l());
  };

  const subscribeLoggedInUser = (listener: () => void) => {
    loggedInUserListeners.add(listener);
    return () => loggedInUserListeners.delete(listener);
  };

  const login = async (username: string, password: string) => {
    const loggedInUser = await trpcClient.mutation(
      'auth.login',
      { username, password },
      { context: { noTokenRefresh: true } }
    );
    //console.log(JSON.stringify(loggedInUser, null, 2));
    setLoggedInUser(loggedInUser);
    return loggedInUser;
  };

  const logout = async () => {
    const logoutResult = await trpcClient.mutation('auth.logout', null, {
      context: { noTokenRefresh: true }
    });
    //console.log(JSON.stringify(logoutResult, null, 2));
    setLoggedInUser(null);
    return logoutResult;
  };

  const refresh = async () => {
    let loggedInUser: LoggedInUser | void | null;
    loggedInUser = await trpcClient
      .mutation('auth.refresh', null, {
        context: { noTokenRefresh: true }
      })
      .catch(() => {
        loggedInUser = null;
      });
    //console.log(JSON.stringify(loggedInUser, null, 2));
    setLoggedInUser(loggedInUser || null);
    return loggedInUser || null;
  };

  const setPassword = async (password: string) => {
    await trpcClient.mutation('users.setPassword', password);

    const loggedInUser = await refresh();

    return loggedInUser;
  };

  const initialize = async () => {
    await refresh().catch(() => {
      /**NOOP*/
    });
    setLoading(false);
  };

  initialize();

  return {
    getLoading,
    subscribeLoading,
    getLoggedInUser,
    subscribeLoggedInUser,
    login,
    logout,
    refresh,
    setPassword
  };
};

type AuthStoreReturnType = ReturnType<typeof createStore>;

const AuthContext = createContext<AuthStoreReturnType | null>(null);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const useAuthContext = () => useContext(AuthContext)!;

//type Props = PropsWithChildren<{}>;
type AuthProviderProps = {
  children: ReactNode;
};

function useLoading(store: AuthStoreReturnType) {
  return useSyncExternalStore(store.subscribeLoading, store.getLoading);
}

function useLoggedInUser<SelectorOutput>(
  store: AuthStoreReturnType,
  selector: (state: LoggedInUser | null) => SelectorOutput | null
) {
  return useSyncExternalStore(
    store.subscribeLoggedInUser,
    useCallback(() => selector(store.getLoggedInUser()), [store, selector])
  );
}

interface History {
  navigate: ReturnType<typeof useNavigate> | null;
}

export const history: History = {
  navigate: null
};
export const authStore = createStore();
export const AuthProvider = ({ children }: AuthProviderProps) => {
  history.navigate = useNavigate();

  return (
    <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
  );
};
export const useAuthLoading = () => useLoading(authStore);
export const useLogin = () => useAuthContext().login;
export const useLogout = () => useAuthContext().logout;
export const useSetPassword = () => useAuthContext().setPassword;
export const useAuthUser = () =>
  useLoggedInUser(authStore, (state) => state?.user || null);
export const useAuthPermissionsForRoles = () =>
  useLoggedInUser(authStore, (state) => state?.permissionsForRoles || null);
export const usePasswordExpired = () =>
  useLoggedInUser(authStore, (state) => state?.passwordExpired || false);
export const useAuthIsLoggedIn = () => useAuthUser() !== null;
